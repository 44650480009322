body {
  /* font-family: Lato,'Helvetica Neue',Helvetica,Arial,sans-serif; */
  font-family: "Source Sans Pro",sans-serif !important;
  color: #333333 !important;
}

.body-container {
  height: 100%;
}

.tall-container {
  height: 90vh;
}

.navbar-custom {
  font-weight: 700;
  border: none;
  /* padding: 20px 0; */
  /* height: 80px; */
  /* z-index: 9999 !important; */
}


.navbar-custom .navbar-brand {
  color: #fff;
}

.navbar-brand {
  padding: 0 !important;
}

.navbar-text {
  color: #fff !important;
  border-color: #fff !important;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-custom .navbar-brand .title {
  font-size: 1.5em;
  text-transform: uppercase;
  text-decoration: none;
}

.navbar-custom .navbar-brand:hover {
  color: white;
}

ul.cards {
  margin-top: 20px;
}

li.card-inline {
  display: inline-block;
  width: 300px;
  height: 326px;
  margin: 5px;
  text-align: center;
}

img.card-image {
  width: 150px;
  padding-top: 20px;
}

.user-details {
  margin-bottom: 40px;
}

.hero {
  text-align: center;
  background: #18BC9C;
  padding: 20px;
  color: #fff;
}

img.employee-image {
  width: 350px;
  margin: 10px 0 20px;
}

td.title {
  text-align: right;
  font-weight: 700;
}

.callout {
  padding: 20px;
  margin: 20px auto;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  width: 1000px;
  border-left-color: #ce4844;
}

.callout h4 {
  color: #ce4844;
  margin-top: 0;
  margin-bottom: 5px;
}

.details-table, .reports {
  width: 1000px;
  margin: 0 auto;
}

.reports {
  margin-top: 30px;
}

img.report-img {
  width: 100px;
  padding-right: 10px;
}

.report-desc {
  width: 230px;
}

.report-desc p {
  margin: 0;
  color: gray
}

.direct-report {
  display: inline-block;
}

.direct-report .card {
  width: 352px;
  margin: 5px;
  padding: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hint {
  color: gray;
  font-size: 11px;
  display: flex;
  margin-top: 0.5rem;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.container.form {
  padding-top: 2rem;
  flex-direction: column;
}

.buttons-row {
  flex-direction: row;
}

.flexible-button {
  display:  flex;
  flex-wrap: wrap;
}
.flexible-button.right {
  margin-left: 1rem;
}

.popup {
  padding: 2rem;
  text-align: left;
}

.popup.input {
  width: 30rem;
}

.popup.centered {
  text-align: center !important;
}

.stretch {
  width: 100%;
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}
.header {
  margin-top: 2rem;
}

html, body, #root
{
  min-height: 100%;
}

.fullscreen-container {
  width: 100vw;
  height: 100vh;
}

.bold {
  font-weight: bold;
}

.fullscreen-container.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-centered {
  display: flex;
  align-items: center;
}

.h-centered {
  display: flex;
  justify-content: center;
}

.h-end {
  display: flex;
  justify-content: end;
}

.login-container {
  height: 90%;
  display: table;
}

.login-form {
  display: table-cell;
  vertical-align: middle;
}

.fading-label {
  position: absolute;
}

.fading-label-background {
  opacity: 0;
}

.login-background {
  width: 100%;
  height: 100%;
  background-color: #fafbff;
}

.navbar-floating-menu {
  position: absolute;
  top: 36;
  right: 0;
  left: 0;
}

.help-content {
  margin-top: 2rem;
  padding-left: 2rem;
}

.help-row {
  display: list-item;
  margin-top: 1rem;
  line-height: 1.5rem;
}

.light-content {
  color: #fff !important; 
}

.light-content .MuiSelect-icon {
  color: #fff !important;
}

.no-decoration::before {
  display: none;
}
.no-decoration::after {
  display: none;
}

.content-page {
  max-width: 90%;
  padding: 24px;
}

.wide-page {
  max-width: 100%;
  padding: 24px;
}

.navbar-marginer {
  height: 80px;
}

.groups-table-header {
  font-size: medium;
}

.categories-table-header {
  font-size: medium;
}

.question-row-label {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-family: "Source Sans Pro",sans-serif;
  font-weight: 400;
  line-height: 1;
}

.question-row-value {
  font-size: 1rem;
}

.MuiInputAdornment-positionStart {
  margin-right: 0 !important;
}
.MuiTypography-colorTextSecondary {
  font-size: 0.8rem !important;
}

.edit-image {
  position: relative !important;
  bottom: -40px !important;
  right: -10px !important;
  display: inline-flex;
}

.logo-container {
  text-align: end;
  width: 250px;
}

.image-hint {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  display: flex;
  position: relative;
  top: 40px;
}

.logo {
  display: block;
  width: 250px;
  height: auto;
}

.logo-thumbnail {
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
}

.plain-button {
  padding: 0;
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

.flag-icon {
  width: 35px;
}

.row {
  display: flex;
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.narrow {
  align-self: baseline !important;
  width: auto !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.popup.wide {
  width: 50vw;
  max-width: 600px;
}

.bc-lightgray {
  background-color: whitesmoke !important;
}

h6 {
  margin-bottom: 0 !important;
}

h5 {
  margin-bottom: 0 !important;
}

h4 {
  margin-bottom: 0 !important;
  font-weight: 600;
}

.subtitle {
  font-weight: bold;
}

.bottom-separator {
  border-bottom-width: 0.5;
  border-bottom-color: whitesmoke;
  border-bottom-style: solid;
}

.floating-footer {
  position: fixed;
  align-self: baseline;
  display: flex;
  bottom: 10vh;
  right: 10vw;
}

.card-medium {
  min-height: 10rem;
}

.card-cover {
  width: 100%;
  height: 140px;
  background-size: cover;
}

.card-cover.partner {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.card-footer {
  padding: 24px;
}

.list-label {
  font-size: 14px;
}

.MuiSelect-icon {
  color: inherit !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: inherit !important;
}

.navbar-text:before {
  /* color: inherit !important; */
  display: none !important;
}

.navbar-text .MuiSelect-select.MuiSelect-select {
  padding-top: 10px;
}

.documents-header {
  position: sticky !important;
  top: 64px !important;
  height: 110px !important;
  background-color: #fff !important;
  color: #333333 !important;
  justify-content: center !important;
}

.sorter-button {
  cursor: pointer;
}

.block {
  display: block !important;
}

.category-dialog-icon-container {
  justify-content: center;
  align-items: center;
}

.category-dialog-icon {
  background-color: white;
  padding: 15px;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.category-dialog-image {
  width: 50%;
  height: auto;
}

.MuiDialog-paper {
  overflow: visible !important;
}

.category_locale {
  font-size: 12px;
  color: #808080;
}

.MuiButton-root {
  outline: none !important;
}

.MuiIconButton-root {
  outline: none !important;
}

.MuiButtonBase-root {
  outline: none !important;
}

.group-preview-icon {
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 40px;
  position: absolute;
  padding: 15px;
  margin-top: -15px;
  margin-left: -15px;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.2);
  object-fit: contain;
}

.category-preview-icon {
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 40px;
  position: absolute;
  padding: 15px;
  margin-top: -15px;
  margin-left: -15px;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.2);
  object-fit: contain;
}

.export-drawer {
  background-color: lightgray;
}

.filter-drawer {
  width: 40vw;
}

.filter-buttons-container {
  align-self: baseline;
}

.client-container {
  background-color: white;
  padding: 30px;
  width: 60vw;
  border-radius: 5px;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.2);
}

.MuiInputBase-input {
  background-color: #f7f9fb !important;
  border-bottom: 2px solid #064b8d !important;
}

.MuiFormLabel-root {
  z-index: 1;
  pointer-events: none;
}

.MuiInput-underline:before {
  display: none !important;
}
.MuiInput-underline:after {
  display: none !important;
}

.guide_image {
  max-height: 200px;
  margin-right: 20px;
  margin-top: 20px;
}


